import PermissionsClass from '@/security/Permission-classes'
import Permissions from '@/security/permissions'

export class AuctionPermissions extends PermissionsClass {
  constructor(currentUser) {
    super(currentUser, {
      read: Permissions.auctionRead,
      create: Permissions.auctionCreate,
      edit: Permissions.auctionEdit,
      delete: Permissions.auctionDestroy
    })
  }
}
