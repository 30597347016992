<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('auction.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    >
    </TableFilter>
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            v-if="hasPermissionToCreate"
            class="btn bg-theme-31 text-white sm:w-auto pb-2 pt-2"
            @click="$router.push('/auction/new')"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
        </div>
      </div>
      <div ref="table" class="relative">
        <loading-spinner
          type="controller"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div class="overflow-x-auto">
          <!-- scrollbar-hidden -->
          <table
            class="table table-report sm:mt-2"
            id="adminTable"
            ref="tableRef"
          >
            <thead>
              <tr>
                <th class="whitespace-nowrap" id="">#</th>
                <th
                  v-for="col in columns"
                  :key="col.name"
                  :id="
                    col.name === 'action'
                      ? 'ignore-2'
                      : col.name === 'avatar'
                      ? 'ignore-3'
                      : ''
                  "
                  class="text-center whitespace-nowrap"
                >
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length">
              <tr v-for="(row, index) in rows" :key="index">
                <td class="w-40">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                    {{ rowIndex(index) }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'auctionNo') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ formatPrice(row.currentBid) }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterInteger(row, 'numOfBids') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDecimal(row, 'minimumBid') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDecimal(row, 'minBidIncrement') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDecimal(row, 'maxBidIncrement') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDecimal(row, 'minTargetPrice') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDecimal(row, 'maxTargetPrice') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row.highestBidder, 'fullName') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row.seller, 'fullName') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ `${row.auctionDuration} ${row.durationUnit}` }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{
                      `${presenterTime(row, 'startDate')} ${presenterDate(
                        row,
                        'startDate'
                      )}`
                    }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{
                      `${presenterTime(row, 'endDate')} ${presenterDate(
                        row,
                        'endDate'
                      )}`
                    }}
                  </div>
                </td>
                <td class="w-40">
                  <div
                    class="flex items-center justify-center whitespace-nowrap"
                    :class="{
                      'text-theme-24':
                        row.status === 'rejected' ||
                        row.status === 'cancelled' ||
                        row.status === 'ended',
                      'text-green-600': row.status === 'running',
                      'text-gray-600': row.status === 'pending'
                    }"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-2 ml-2" />
                    {{ i18n(`common.${row.status}`) }}
                  </div>
                </td>
                <td class="table-report__action w-56" id="ignore-6">
                  <div
                    class="flex justify-center items-center gap-3 text-theme-24"
                  >
                    <router-link
                      class="text-green-600 dark:text-theme-30 flex items-center gap-2 whitespace-nowrap"
                      :to="`/auction/${row.id}/edit`"
                      v-if="hasPermissionToEdit"
                    >
                      <EditIcon class="w-4 h-4" /> {{ i18n('common.edit') }}
                    </router-link>

                    <router-link
                      class="text-theme-10 dark:text-theme-30 flex items-center gap-2 whitespace-nowrap"
                      :to="`/auction/${row.id}`"
                    >
                      <EyeIcon class="w-4 h-4" /> {{ i18n('common.view') }}
                    </router-link>
                    <!-- <a
                      v-if="hasPermissionToDestroy"
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#delete-modal-preview"
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      @click="selectedId = row.id"
                      ><Trash2Icon class="w-4 h-4 mr-1 ml-1" />{{
                        i18n('common.delete')
                      }}</a
                    > -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-empty-page
            v-if="!rows.length && !loading"
            :label="i18n('empty.admin')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
        <Pagination
          v-if="rows.length"
          :page="currentPage"
          :pageSize="pagination.limit"
          :rowsNumber="pagination.total"
          :showSizeChanger="true"
          :disabled="!rows.length"
          :isFirstPage="isFirstPage"
          :isLastPage="isLastPage"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
        ></Pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineComponent } from 'vue'
import { GenericModel } from '@/shared/model/generic-model'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'
import { AuctionPermissions } from './auction-permissions'

export default defineComponent({
  data() {
    return {
      filterInput: [],
      columns: [
        {
          name: 'auctionNo',
          field: 'auctionNo',
          label: 'auction.fields.auctionNo',
          align: 'center'
        },
        {
          name: 'currentBid',
          field: 'currentBid',
          label: 'auction.fields.currentBid',
          align: 'center'
        },
        {
          name: 'numOfBids',
          field: 'numOfBids',
          label: 'auction.fields.numOfBids',
          align: 'center'
        },
        {
          name: 'minimumBid',
          field: 'minimumBid',
          label: 'auction.fields.minimumBid',
          align: 'center'
        },
        {
          name: 'minBidIncrement',
          field: 'minBidIncrement',
          label: 'auction.fields.minBidIncrement',
          align: 'center'
        },
        {
          name: 'maxBidIncrement',
          field: 'maxBidIncrement',
          label: 'auction.fields.maxBidIncrement',
          align: 'center'
        },
        {
          name: 'minTargetPrice',
          field: 'minTargetPrice',
          label: 'auction.fields.minTargetPrice',
          align: 'center'
        },
        {
          name: 'maxTargetPrice',
          field: 'maxTargetPrice',
          label: 'auction.fields.maxTargetPrice',
          align: 'center'
        },
        {
          name: 'highestBidder',
          field: 'highestBidder',
          label: 'auction.fields.highestBidder',
          align: 'center'
        },
        {
          name: 'seller',
          field: 'seller',
          label: 'auction.fields.seller',
          align: 'center'
        },
        {
          name: 'auctionDuration',
          field: 'auctionDuration',
          label: 'auction.fields.auctionDuration',
          align: 'center'
        },
        {
          name: 'startDate',
          field: 'startDate',
          label: 'auction.fields.startDate',
          align: 'center'
        },
        {
          name: 'endDate',
          field: 'endDate',
          label: 'auction.fields.endDate',
          align: 'center'
        },
        {
          name: 'status',
          field: 'status',
          label: 'auction.fields.status',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'auction/list/rows',
      currentUser: 'auth/currentUser',
      currentPage: 'auction/list/currentPage',
      isFirstPage: 'auction/list/isFirstPage',
      isLastPage: 'auction/list/isLastPage',
      pagination: 'auction/list/pagination',
      loading: 'auction/list/loading'
    }),
    hasPermissionToCreate() {
      return new AuctionPermissions(this.currentUser).create
    },
    hasPermissionToEdit() {
      return new AuctionPermissions(this.currentUser).edit
    },
    hasPermissionToDestroy() {
      return new AuctionPermissions(this.currentUser).delete
    },
    rowIndex() {
      return index => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    statusOptions() {
      return [
        { label: this.i18n('common.pending'), value: 'pending' },
        { label: this.i18n('common.rejected'), value: 'rejected' },
        { label: this.i18n('common.cancelled'), value: 'cancelled' },
        { label: this.i18n('common.running'), value: 'running' },
        { label: this.i18n('common.ended'), value: 'ended' }
      ]
    },
    filterFields() {
      return [
        {
          label: this.i18n('auction.fields.auctionNo'),
          value: 'auctionNo',
          type: 'exactText'
        },
        {
          label: this.i18n('auction.fields.startDate'),
          value: 'startDate',
          type: 'date'
        },
        {
          label: this.i18n('auction.fields.endDate'),
          value: 'endDate',
          type: 'date'
        },
        {
          label: this.i18n('auction.fields.status'),
          value: 'status',
          type: 'list',
          options: this.statusOptions
        }
      ]
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.auction')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.auction')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'auction/list/doFetch',
      doFetchNextPage: 'auction/list/doFetchNextPage',
      doFetchPreviousPage: 'auction/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'auction/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'auction/list/doChangePaginationPageSize'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE}` : `${PRICE}`
    },
    async doFilter(filter, orderBy) {
      this.filterInput = []
      if (!filter) return
      if (filter[0].field === 'status' && filter[0].value.length === 0) {
        filter[0].value = 'pending'
      }
      this.filterInput = filter
      // if (Array.isArray(filter)) {
      //   this.filterInput.push(...filter)
      // } else {
      //   this.filterInput.push(filter)
      // }
      await this.doFetchFirstPage(orderBy)
    },
    async doResetFilter() {
      this.filterInput = []
      await this.doFetchFirstPage()
    },
    async doFetchFirstPage(orderBy = 'createdAt') {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: this.filterInput,
        orderBy,
        pagination: {
          limit: 10,
          sortBy: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'auctionTable', 'auctions')
    },
    onDownloadPdf() {
      $h.downloadPdf('#auctionTable', 'auction', [1, 2, 8])
    },
    onPrint() {
      print({
        printable: 'auctionTable',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 300,
        ignoreElements: [
          'ignore-1',
          'ignore-2',
          'ignore-3',
          'ignore-4',
          'ignore-5',
          'ignore-6'
        ]
      })
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
